import { render, staticRenderFns } from "./shoplogin.vue?vue&type=template&id=2a47e409&scoped=true"
import script from "./shoplogin.vue?vue&type=script&lang=js"
export * from "./shoplogin.vue?vue&type=script&lang=js"
import style0 from "./shoplogin.vue?vue&type=style&index=0&id=2a47e409&prod&lang=css"
import style1 from "./shoplogin.vue?vue&type=style&index=1&id=2a47e409&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a47e409",
  null
  
)

export default component.exports